import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';

import routesMap from '../../../Routes';

import PaymentPageBrand from '../../../components/order/PaymentPageBrand';
import callApi from '../../../services/api';
import { getBrowserInfo, registerBrandCard } from '../../../services/mangopay';
import Layout from '../../../layouts/index';

class PaymentContainerBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submitCard = this.submitCard.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    if (window.location.hostname !== 'localhost') {
      window.onbeforeunload = function () {
        return true;
      };
    }
  }

  redirect3DS = (redirectUrl) => {
    if (redirectUrl) {
      window.onbeforeunload = null;
      window.location = redirectUrl;
    } else {
      navigate(routesMap.SuccessPaymentBrand.url);
    }
  }

  submitCard(cardData, brandId) {
    this.setState({ error: false, isLoading: true, errorType: undefined });
    return registerBrandCard(cardData, brandId)
      .then((card) => {
        const BrowserInfo = getBrowserInfo();
        return callApi(`brands/${brandId}/payment`, 'post', { cardToken: card.CardId, BrowserInfo });
      })
      .then((res) => { this.redirect3DS(res.redirectUrl); })
      .catch((response) => {
        this.setState({ error: true, isLoading: false, errorType: response.error });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          console.error(response);
        }
      });
  }

  render() {
    const { location } = this.props;
    const { error, errorType, isLoading } = this.state;
    if (typeof location === 'undefined') return null;
    return (
      <Layout>
        <Router
          location={location}
          key={location.key}
        >
          <PaymentPageBrand
            path="/enregistrer-un-moyen-de-paiement/:brandId"
            error={error}
            errorType={errorType}
            isLoading={isLoading}
            submit={this.submitCard}
          />
        </Router>
      </Layout>
    );
  }
}

PaymentContainerBrand.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
  }),
};

PaymentContainerBrand.defaultProps = {
  location: {},
};

export default PaymentContainerBrand;
